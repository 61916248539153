<template>
  <Header></Header>
  <div class="hei800">
    <SwiperView :data="swiperCom()"></SwiperView>
  </div>
  <GoodProduct></GoodProduct>
  <ApplyTerminal ></ApplyTerminal>
  
  <AboutUs
    :html="richHtml"
    :htmlEn="richHtml_en"
    :pageTitle="modulsTitle.about_us_title"
    :pageTitleEn="modulsTitle.about_us_title_en"
    :subPageTitle="modulsTitle.subPageTitle"
    :subPageTitleEn="modulsTitle.subPageTitleEn"
  ></AboutUs>
  <div class="home_bottom_bg">
    <NewMessage
      :moduleTitle="modulsTitle.information_title"
      :moduleTitleEn="modulsTitle.information_title_en"
      :datas="messages"
    ></NewMessage>
    <Footer></Footer>
  </div>
</template>

<script setup>
import Header from "@/common/pageComponents/header/index.vue";
import Footer from "@/common/pageComponents/Footer.vue";
import SwiperView from "@/common/baseComponents/SwiperView.vue";
import ApplyTerminal from "@/components/home/ApplyTerminal.vue";
import ApplyTerminalMobile from "@/components/home/ApplyTerminalMobile.vue";
import GoodProduct from "@/components/home/GoodProduct.vue";
import AboutUs from "@/components/home/AboutUs.vue";
import NewMessage from "@/components/home/NewMessage.vue";
import { ref, onBeforeMount, onMounted, computed } from "vue";
import { homeBanners, homeRichText, messageList, reqArticleCategoy } from "@/api/home";
import { reactive } from "vue";
import { useUserStore } from "@/stores/user";

computed({});

let swiperData = ref([]);

let innerWidth = ref(0);

let richHtml = ref("");
let richHtml_en = ref("");

let messages = ref([]);

let modulsTitle = reactive({
  product_title: "",
  product_title_en: "",
  application_title: "",
  application_title_en: "",
  about_us_title: "",
  about_us_title_en: "",
  information_title: "",
  information_title_en: "",
  subPageTitle: "",
  subPageTitleEn: ""
});

onBeforeMount(() => {
  innerWidth.value = window.innerWidth;
  window.addEventListener("resize", function (event) {
    innerWidth.value = window.innerWidth;
  });
});

onMounted(() => {
  getBanners();
  getRichText();
  getMessageList();
});

const getBanners = async () => {
  let res = await homeBanners();
  if (res.code === 200001) {
    swiperData.value = res.data.map((item) => {
      return {
        img: item.img,
        img_en: item.img_en,
        jump_url: item.jump_url,
        id: item.id,
        is_phone_show: item.is_phone_show,
      };
    });
    console.log(swiperData.value)
  }
};

const getRichText = async () => {
  let res = await homeRichText();
  if (res.code === 200001) {
    let {
      product_title,
      product_title_en,
      application_title,
      application_title_en,
      about_us_title,
      about_us_title_en,
      information_title,
      information_title_en,
      about_us_subtitle_en,
      about_us_subtitle,
      
    } = res.data;

    richHtml.value = res.data.about_us_content;
    richHtml_en.value = res.data.about_us_content_en;

    modulsTitle.product_title = product_title;
    modulsTitle.product_title_en = product_title_en;
    modulsTitle.application_title = application_title;
    modulsTitle.application_title_en = application_title_en;
    modulsTitle.about_us_title = about_us_title;
    modulsTitle.about_us_title_en = about_us_title_en;
    modulsTitle.information_title = information_title;
    modulsTitle.information_title_en = information_title_en;
    modulsTitle.subPageTitle = about_us_subtitle;
    modulsTitle.subPageTitleEn = about_us_subtitle_en;
  }
};

const getMessageList = async () => {
  let res = await messageList();
  if (res.code === 200001) {
    let dataArr = res.data.list.slice(0, 4);
    messages.value = dataArr.map((item) => {
      return {
        title: item.article_category.title,
        title_en: item.article_category.title_en,
        desc: item.title,
        desc_en: item.title_en,
        id: item.id,
        img: item.cover_img,
        created_at: item.created_at.substring(0,11),
        article_category: item.article_category
      };
    });
  }
  let data = await reqArticleCategoy();
  localStorage.setItem("articleCategory", JSON.stringify(data.data))
};

const swiperCom = (isMobile) => {
  if (innerWidth.value < 769) {
    return swiperData.value.filter((item) => item.is_phone_show === 1);
  } else if (innerWidth.value > 769 && innerWidth.value < 1024) {
    return swiperData.value.filter((item) => item.is_phone_show === 1);
  } else {
    return swiperData.value.filter((item) => item.is_phone_show === 0);
  }
};
</script>

<style lang="less" scoped>
.hei800 {
  width: 100%;
  height: 80vh;
  @media (max-width: 769px) {
    height: 100%;
  }
  @media (min-width: 770px)  and (max-width: 1024px) {
    height: 80vh;
  }
}

.home_bottom_bg {
  width: 100%;
  // min-height: 1244px;
  // background: url("@/assets/images/home_bottom_bg.png") 50% 50% / cover;
}
</style>
